<template>
 <PageHeading
  :button-title="$t('new rule group')"
  :has-meta-data="false"
  :has-search="true"
  :has-action-button="true"
  :title="$t('inbound rules groups')"
  :has-permission="
   userPermissions.permissions.includes('smart-routings.*') ||
   userPermissions.permissions.includes('smart-routings.create')
  "
  @open="(this.openForm = !this.openForm), (this.openUpdateForm = false)"
  @update:search="$emit('update:search', $event)"
 />
 <div class="flex flex-col" v-if="this.openForm">
  <div class="-my-0 overflow-x-auto sm:-mx-6 lg:-mx-0">
   <div class="pt-5 align-middle inline-block min-w-full sm:px-6 lg:px-10">
    <div class="overflow-hidden border rounded-md">
     <CreateNewCallQueue
      :title="$t('callqueue.newFormTitleGroup')"
      :subTitle="$t('callqueue.formSubTitleGroup')"
      @activeBanner="$emit('activeBanner'), getCallQueuesGroups()"
      @successMsg="$emit('successMsg', $event)"
      @success="$emit('success', $event)"
      @closeForm="this.openForm = false"
     />
    </div>
   </div>
  </div>
 </div>

 <div class="flex flex-col" v-if="this.openUpdateForm">
  <div class="-my-0 overflow-x-auto sm:-mx-6 lg:-mx-0">
   <div class="pt-5 align-middle inline-block min-w-full sm:px-6 lg:px-10">
    <div class="overflow-hidden border rounded-md">
     <CreateNewCallQueue
      :title="$t('callqueue.updateFormTitleGroup')"
      :editData="editData"
      :openUpdateForm="openUpdateForm"
      @activeBanner="$emit('activeBanner'), getCallQueuesGroups()"
      @successMsg="$emit('successMsg', $event)"
      @success="$emit('success', $event)"
      @closeForm="this.openUpdateForm = false"
     />
    </div>
   </div>
  </div>
 </div>

 <div class="flex flex-col">
  <div class="-my-0 sm:-mx-6 lg:-mx-0">
   <div v-if="tbody?.length > 0" class="align-middle inline-block min-w-full sm:px-6 lg:px-10">
    <CallQueuesGroupsTable
     :account="this.account"
     :thead="thead"
     :tbody="filteredDatas()"
     @openEditForm="openEditForm($event)"
     @deleteList="(...args) => deleteList(...args)"
     @activeBanner="$emit('activeBanner')"
     @successMsg="$emit('successMsg', $event)"
     @success="$emit('success', $event)"
     @sort="(this.currentSort = $event), sort($event)"
    />
   </div>

   <div v-else class="justify-center pt-20">
    <EmptyState
     :title="$t('emptyStateTitle', { title: $t('callqueueGroups') })"
     :subtitle="$t('emptyStateSubtitle')"
    />
   </div>
  </div>
 </div>

 <ask-confirmation-dialog ref="askConfirmationDialog"></ask-confirmation-dialog>
</template>

<script>
import axios from "axios";

import AskConfirmationDialog from "../components/AskConfirmationDialog.vue";
import PageHeading from "../components/PageHeading.vue";
import CallQueuesTable from "../components/CallQueuesTable.vue";
import CallQueuesGroupsTable from "../components/CallQueuesGroupsTable.vue";

import TablePagination from "../components/TablePagination.vue";
import EmptyState from "../components/EmptyState.vue";

import UpdateCallQueueForm from "../components/UpdateCallQueueForm.vue";
import CreateNewCallQueue from "../components/CreateNewCallQueue.vue";

const account = localStorage.getItem("account");
const userPermissions = JSON.parse(localStorage.getItem("userPermissions"));

export default {
 name: "CallQueuesGroups",
 props: ["search"],
 components: {
  AskConfirmationDialog,
  TablePagination,
  CallQueuesGroupsTable,
  CallQueuesTable,
  PageHeading,
  CreateNewCallQueue,
  UpdateCallQueueForm,
  EmptyState,
 },
 data() {
  return {
   thead: [{ name: "callqueueTable.name", sort: "name", isSort: false }],
   tbody: [],
   editData: {},
   account,
   openForm: false,
   currentSort: "id",
   currentSortDir: "asc",
   callQueueId: "",
   callQueueName: "",
   openUpdateForm: false,
   userPermissions,
  };
 },
 methods: {
  openEditForm(data) {
   this.openForm = false;
   this.openUpdateForm = true;
   this.callQueueId = data.id;
   this.editData = data;
   window.scrollTo(0, 100);
  },
  async getCallQueuesGroups() {
   this.$emit("setLoading", true);
   try {
    const res = await axios.get(
     `${this.$cookie.getCookie("API")}/api/v1/callQueueGroups?customerAccount=${this.account}`,
     {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
     }
    );

    this.$emit("setLoading", false);

    this.tbody = res.data;
   } catch (error) {
    this.$emit("setLoading", false);
    this.errorHandling(error);
   }
  },
  sort(s) {
   if (s === this.currentSort) {
    this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
   }
   this.currentSort = s;
  },
  sortedCallQueuesGroups() {
   return this.tbody.sort((a, b) => {
    let toBeSorted = this.currentSort;
    let modifier = 1;
    if (this.currentSortDir === "desc") modifier = -1;
    if (toBeSorted === "number") {
     if (parseFloat(a[toBeSorted]) < parseFloat(b[toBeSorted])) return -1 * modifier;
     if (parseFloat(a[toBeSorted]) > parseFloat(b[toBeSorted])) return 1 * modifier;
    } else {
     if (a[toBeSorted] < b[toBeSorted]) return -1 * modifier;
     if (a[toBeSorted] > b[toBeSorted]) return 1 * modifier;
    }
    return 0;
   });
  },
  filteredDatas() {
   return this.sortedCallQueuesGroups().filter((res) => {
    if (this.search) {
     const searchLower = this.search.toLowerCase();

     const nameMatch = res.name.toLowerCase().includes(searchLower);
     const numberMatch = res.number.toLowerCase().includes(searchLower);
     const activeMatch = res.number.active;

     return nameMatch || numberMatch || activeMatch;
    }
    return res;
   });
  },

  // filteredDatas() {
  //  return this.sortedCallQueuesGroups().filter((list) => {
  //   return (
  //    String(list.name).toLowerCase().includes(String(this.search).toLowerCase()) ||
  //    String(list.number).includes(String(this.search))
  //   );
  //  });
  // },
  async deleteList(...args) {
   let id = args[0];
   let name = args[1];
   let title = this.$t("deleteCallQueueGroup", { name: name });
   let confirmationMessage = this.$t("wishToContinue");
   let confirmButton = this.$t("delete");
   let goBack = this.$t("cancel");
   let successMsg = this.$t("elementDeletedSuccessfully", { element: this.$t("callqueueGroup") });
   const options = {
    method: "DELETE",
    url: `${this.$cookie.getCookie("API")}/api/v1/callQueueGroups/${id}`,
    params: { customerAccount: this.account },
    headers: {
     "Content-Type": "application/json",
     Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
   };
   const r = await this.$refs.askConfirmationDialog.show({
    title: title,
    message: confirmationMessage,
    okButton: confirmButton,
    cancelButton: goBack,
    action: "delete",
   });
   if (r) {
    axios
     .request(options)
     .then((response) => {
      this.$emit("activeBanner");
      this.$emit("success", true);
      this.$emit("successMsg", successMsg);
      this.getCallQueuesGroups();
     })
     .catch((err) => {
      console.error(err);
      this.$emit("activeBanner");
      this.$emit("success", false);
      this.$emit("successMsg", this.errorDataResponse);
     });
   }
  },
 },
 mounted() {
  this.getCallQueuesGroups();
 },
};
</script>

<style></style>
